import React from 'react';
import './App.css';
import ParentComponent from './components/ParentComponent';

function App() {
  return (
    <div className="app-container">
      <div className="header">Bowling Game</div>
      <ParentComponent />
    </div>
  );
}

export default App;
