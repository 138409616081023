import React from 'react';

const PlayerList = ({ players, gameStarted }) => {
  return (
    <div className="player-list">
      <h2>Players</h2>
      <ul>
        {players.map((player, index) => (
          <li key={index}>
            {player.name} - {player.average}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayerList;
