// src/components/Frame.js
import React from 'react';

const Frame = ({ frameId, frameIndex, rolls, onRollChange }) => {
  const handleRollChange = (e, rollIdx) => {
    const value = e.target.value === 'X' ? 10 : e.target.value === '/' ? 10 - (rolls[0] || 0) : parseInt(e.target.value, 10);
    onRollChange(frameId, rollIdx, isNaN(value) ? null : value);
  };

  return (
    <div className="frame">
      <input
        class="frame-input"
        type="text"
        value={rolls[0] !== null ? (rolls[0] === 10 ? 'X' : rolls[0]) : ''}
        onChange={(e) => handleRollChange(e, 0)}
        maxLength={1}
        pattern="[0-9X]"
        disabled={rolls[0] === 10 && frameIndex < 9}
      />
      <input
        class="frame-input"
        type="text"
        value={rolls[1] !== null ? (rolls[1] === 10 ? 'X' : rolls[1] === 10 - rolls[0] ? '/' : rolls[1]) : ''}
        onChange={(e) => handleRollChange(e, 1)}
        maxLength={1}
        pattern="[0-9X/]"
        disabled={rolls[0] === 10 && frameIndex < 9}
      />
      {frameIndex === 9 && (rolls[0] === 10 || (rolls[0] + (rolls[1] || 0)) === 10) && (
        <input
          class="frame-input"
          type="text"
          value={rolls[2] !== null ? (rolls[2] === 10 ? 'X' : rolls[2]) : ''}
          onChange={(e) => handleRollChange(e, 2)}
          maxLength={1}
          pattern="[0-9X]"
        />
      )}
    </div>
  );
};

export default Frame;
