import React, { useState } from 'react';

const PlayerForm = ({ addPlayer, gameStarted }) => {
  const [name, setName] = useState('');
  const [average, setAverage] = useState('');

  const handleAddPlayer = (e) => {
    e.preventDefault();
    if (name && average && !gameStarted) {
      addPlayer({ name, average: parseInt(average, 10) });
      setName('');
      setAverage('');
    }
  };

  return (
    <form onSubmit={handleAddPlayer}>
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={gameStarted}
        required
      />
      <input
        type="number"
        placeholder="Average"
        value={average}
        onChange={(e) => setAverage(e.target.value)}
        disabled={gameStarted}
        required
      />
      <button type="submit" disabled={gameStarted}>Add Player</button>
    </form>
  );
};

export default PlayerForm;
