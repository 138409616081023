import React, { useState } from 'react';
import PlayerForm from './PlayerForm';
import PlayerList from './PlayerList';
import GameBoard from './GameBoard';

const ParentComponent = () => {
  const [players, setPlayers] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);

  const addPlayer = (player) => {
    if (!gameStarted) {
      setPlayers([...players, player]);
    }
  };

  const handleStartGame = () => {
    if (players.length === 0) {
      alert("You must add at least one player to start the game.");
      return;
    }
    setGameStarted(true);
    setGameEnded(false);
  };

  const handleEndGame = () => {
    setGameStarted(false);
    setGameEnded(true);
    setPlayers([]); // Clear players
  };

  return (
    <div className="game-container">
      <div className="player-section">
        <div className="player-form">
          <h2>Register Players</h2>
          <PlayerForm addPlayer={addPlayer} gameStarted={gameStarted} />
        </div>
        <PlayerList players={players} gameStarted={gameStarted} />
        {!gameStarted && players.length > 0 && (
          <button onClick={handleStartGame}>
            Start Game
          </button>
        )}
        {gameEnded && <h2>Game Over</h2>}
      </div>
      <div className="game-section">
        {gameStarted && (
          <>
            <GameBoard players={players} />
            <button onClick={handleEndGame}>End Game</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ParentComponent;
