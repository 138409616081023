import React, { useState, useEffect } from 'react';
import Frame from './Frame';
import { v4 as uuidv4 } from 'uuid';

const GameBoard = ({ players }) => {
  const [scores, setScores] = useState({});

  useEffect(() => {
    if (players.length === 0) {
      setScores({});
    } else {
      setScores(
        players.reduce((acc, player) => {
          acc[player.name] = Array(10).fill(null).map(() => ({
            id: uuidv4(),
            rolls: [null, null, null],
            total: 0,
          }));
          return acc;
        }, {})
      );
    }
  }, [players]);

  const handleRollChange = (playerName, frameId, rollIndex, value) => {
    const newScores = { ...scores };
    const playerScores = newScores[playerName];
    const frame = playerScores.find(f => f.id === frameId);

    const numericValue = value === 'X' ? 10 : value === '/' ? 10 - (frame.rolls[0] || 0) : parseInt(value, 10);
    frame.rolls[rollIndex] = isNaN(numericValue) ? null : numericValue;

    updateScores(playerScores);
    setScores(newScores);
  };

  const updateScores = (playerScores) => {
    let totalScore = 0;

    for (let i = 0; i < playerScores.length; i++) {
      const [roll1, roll2, roll3] = playerScores[i].rolls;
      let frameScore = roll1 + (roll2 || 0) + (roll3 || 0);

      if (i < 9) {
        if (roll1 === 10) {
          frameScore = 10 + getStrikeBonus(playerScores, i);
        } else if (roll1 + roll2 === 10) {
          frameScore = 10 + getSpareBonus(playerScores, i);
        } else {
          frameScore = roll1 + (roll2 || 0);
        }
      }

      totalScore += frameScore;
      playerScores[i].total = totalScore;
    }

    const updatedScores = { ...scores, [playerScores[0].name]: playerScores };
    setScores(updatedScores);
  };

  const getStrikeBonus = (playerScores, frameIndex) => {
    if (frameIndex >= 8) {
      return (playerScores[frameIndex + 1]?.rolls[0] || 0) +
        (playerScores[frameIndex + 1]?.rolls[1] || 0);
    } else {
      if (playerScores[frameIndex + 1]?.rolls[0] === 10) {
        return 10 + (playerScores[frameIndex + 2]?.rolls[0] || 0);
      } else {
        return (playerScores[frameIndex + 1]?.rolls[0] || 0) +
          (playerScores[frameIndex + 1]?.rolls[1] || 0);
      }
    }
  };

  const getSpareBonus = (playerScores, frameIndex) => {
    return playerScores[frameIndex + 1]?.rolls[0] || 0;
  };

  return (
    <div className="game-board">
      <table>
        <thead>
        <tr>
          <th>Player</th>
          {[...Array(10)].map((_, i) => (
            <th key={i}>Frame {i + 1}</th>
          ))}
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        {players.map((player) => (
          <tr key={player.name}>
            <td>{player.name}</td>
            {scores[player.name]?.map(({ id, rolls, total }, frameIndex) => (
              <td key={id}>
                <Frame
                  frameId={id}
                  frameIndex={frameIndex}
                  rolls={rolls}
                  onRollChange={(frameId, rollIdx, value) =>
                    handleRollChange(player.name, frameId, rollIdx, value)
                  }
                />
                <div className="frame-total">
                  {total !== undefined ? total : ''}
                </div>
              </td>
            ))}
            <td>{scores[player.name] ? scores[player.name][9].total : ''}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default GameBoard;
